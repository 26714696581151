<template>
  <div>
    <label>
      <p v-if="label">{{ label }}</p>

      <textarea
        v-if="type == 'textarea'"
        :placeholder="placeholder"
        :value="value"
        @blur="$emit('blur')"
        v-on:input="$emit('input', $event.target.value)"
      ></textarea>

      <input
        v-else
        :type="type"
        :placeholder="placeholder"
        :value="value"
        @blur="$emit('blur')"
        v-on:input="$emit('input', $event.target.value)"
      />

      <p class="input-error">{{ errorMessage ? errorMessage : "" }}</p>
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  data() {
    return {};
  },
  emits: ["input", "blur"],

  props: {
    type: {
      default: "text",
      required: false
    },
    placeholder: {
      required: false
    },
    label: {
      required: false
    },
    errorMessage: {
      type: String
    },
    value: {
      type: String
    }
  }
};
</script>

<style scoped>
input,
textarea {
  display: block;
  width: 100%;
  padding: 1rem 10px;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #57595a;
  font-size: 1rem;
  font-family: Lato;
}

input::placeholder,
textarea::placeholder {
  color: #8e8e8e;
}

input:focus,
textarea:focus {
  outline: 1px solid #2b55ed;
}

label {
  color: #57595a;
  font-size: 14px;
  line-height: 1.8;
  position: relative;
}

.input-error {
  color: rgb(234, 0, 0);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
}
</style>
