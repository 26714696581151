<script>
export default {};
</script>
<template>
  <div class="modal">
    <div class="modal-body">
      <slot> </slot>
    </div>
  </div>
</template>
<style scoped>
.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  animation: fadeIn 150ms ease-in;
  /* display: grid; */
  /* place-items: center; */

  display: flex;
  justify-content: center;

  padding: 3rem;
}

.modal-body {
  width: 100%;
  margin-top: 6rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
