<script>
export default {
  emits: ["button-clicked"],
  props: {
    type: {
      type: String,
      default: "primary"
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    buttonClass() {
      if (this.type === "primary") {
        return "button__primary";
      } else if (this.type === "secondary") {
        return "button__secondary";
      }
    }
  }
};
</script>
<template>
  <button class="button" :class="buttonClass" @click="$emit('button-clicked')">
    <slot></slot>
  </button>
</template>
<style scoped>
.button {
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.button__primary {
  background-color: var(--deep-green);
  color: #ffffff;
}

.button__primary:hover {
  background-color: #38a146d0;
}

.button__secondary {
  background-color: #ffffff;
  color: var(--deep-green);
  border: 1px solid var(--deep-green);
}

.button__secondary:hover {
  background-color: #38a14615;
}
</style>
