<template>
  <div>
    <portal to="modal">
      <TheModal v-if="isModalOpen">
        <div class="confirm-withdrawal">
          <div v-if="$store.state.user.phone">
            <p>
              Please confirm that you want this phone number
              <span> {{ $store.state.user.phone }} </span> to be recharged with
              the sum of 1,000 naira
            </p>

            <div class="flex gap-1 mt-1">
              <TheButton type="secondary" @button-clicked="closeModal"
                >Close</TheButton
              >
              <TheButton
                :disabled="requestingBonusPayment"
                @button-clicked="withdrawBonus"
                >CONFIRM</TheButton
              >
            </div>
          </div>

          <div v-else>
            <p>
              Sorry your withdrawal cannot be processed because you don't have a
              phone number on this account. Please kindly update your account
              with your mobile number
            </p>

            <div class="flex gap-1 mt-1">
              <TheButton type="secondary" @button-clicked="closeModal"
                >Close</TheButton
              >

              <TheButton :disabled="requestingBonusPayment">
                <RouterLink to="/user/account">
                  UPDATE ACCOUNT
                </RouterLink>
              </TheButton>
            </div>
          </div>
        </div>
      </TheModal>
    </portal>
    <h2 class="section-header">
      <img src="@/assets/images/wallet-black.svg" alt="wallet" />
      Wallet
    </h2>
    <div class="modal-bg"></div>
    <div class="wallets">
      <div class="wallet">
        <div class="wallet-action">
          <div class="hint-container">
            <img src="@/assets/images/info.svg" alt="" />
            <div class="hint">
              <span class="arrow"></span>
              <p>
                Ready amount is the amount you have accrued as a result of your
                activities, your team activities & bonuses paid. The amount in
                this wallet can be used and/or transfered to your bank account
                by way of withdrawal.
              </p>
            </div>
          </div>
          <!-- <button class="withdraw-btn" @click="withdraw()">
            Withdraw
          </button> -->
          <button
            class="withdraw-btn"
            data-toggle="modal"
            data-target="#withdraw-options"
          >
            Withdraw
          </button>

          <p></p>
        </div>
        <p class="price">{{ formattedBalance(readyBalance) }}</p>
        <p class="wallet-description">Ready</p>
        <div class="expected">
          {{ currentMonth }}:
          <span class="expected-amount">{{
            formattedBalance(readyThisMonth)
          }}</span>
        </div>
      </div>
      <!-- <div class="wallet">
        <div class="wallet-action">
          <div class="hint-container">
            <img src="@/assets/images/info.svg" alt="" />
            <div class="hint">
              <span class="arrow"></span>
              <p>
                Bonus amount is the amount you have accrued as a result of
                activating you account. The amount in this wallet can only be
                used to recharge your mobile phone which is sent to the number
                associated with account when you click the withdraw bonus
                button.
              </p>
            </div>
          </div>
          <button
            v-if="$store.state.activatedStatus == 0"
            class="withdraw-btn"
            @click="openModal"
          >
            Withdraw Bonus
          </button>
          <button
            v-else-if="$store.state.activatedStatus == 1"
            class="withdraw-btn"
          >
            Request processing.....
          </button>
        </div>
        <p class="price">{{ formattedBalance(bonusBalance) }}</p>
        <p class="wallet-description">Ready</p>
        <div class="expected">
          Activation Bonus
          <span class="expected-amount">{{
            formattedBalance(readyThisMonth)
          }}</span>
        </div>
      </div> -->

      <div class="wallet">
        <div class="wallet-action">
          <div class="hint-container">
            <img src="@/assets/images/info.svg" alt="" />
            <div class="hint">
              <span class="arrow"></span>
              <p>
                Expected amont is the amount you are expecting as a result of
                your activities, your team’s activities and your bonuses to be
                paid when you hit the next milestone. The amount in this wallet
                cannot be used until it enters your ready wallet.
              </p>
            </div>
          </div>
        </div>
        <p class="price">{{ formattedBalance(expectedBalance) }}</p>
        <p class="wallet-description">Expected</p>
        <div class="expected">
          {{ currentMonth }}:
          <span class="expected-amount">{{
            formattedBalance(expectedThisMonth)
          }}</span>
        </div>
      </div>
    </div>

    <section class="section-two">
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Type</th>
              <th>Amount (&#8358;)</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <!-- when readystatus is 0 means the user has not been credited for the transaction -->
          <!-- when readystatus is 1 means the user has been credited for the transaction -->
          <tbody>
            <tr v-for="transaction in allTransactions" :key="transaction.id">
              <td>{{ formatDate(transaction.created_at) }}</td>
              <td>{{ transaction.description }}</td>
              <td class="capitalize">{{ transaction.transactiontype }}</td>
              <td>{{ toTwoDecimal(transaction.amount) }}</td>
              <td class="text-center">
                <span
                  :class="[
                    transaction.readystatus == 0 ? 'failed' : '',
                    transaction.readystatus == 1 ? 'success' : '',
                  ]"
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="empty"
          v-if="allTransactions.length === 0 && fetchData === 'loaded'"
        >
          No Records
        </div>
        <div class="loading-container" v-if="fetchData === 'loading'">
          <Loader />
        </div>
      </div>

      <!-- Modal to choose withdrawal method ends -->
      <div class="modal fade" id="withdraw-options">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-top">
              <p>Choose a Withdrawal Method.</p>
              <span data-dismiss="modal" @click="withdrawalType = ''">X</span>
            </div>
            <p class="option-text">
              Please select your preferred withdrawal method from the options
              below.
            </p>
            <hr />
            <div class="modal-body text-center">
              <div class="d-flex flex-column">
                <div
                  class="option-withdraw"
                  @click="withdrawalType = 'safe'"
                  :class="[
                    withdrawalType == 'safe'
                      ? 'activeTypeWithdrawal'
                      : 'option-withdraw',
                  ]"
                >
                  <!-- <img src="" alt="safe"> -->
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="32"
                      viewBox="0 0 46 32"
                      fill="none"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="45"
                        height="31"
                        rx="5.5"
                        fill="white"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="45"
                        height="31"
                        rx="5.5"
                        stroke="#F2F4F7"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M29.0641 14.3761L23.3859 10.4605C23.1662 9.84324 22.9249 9.7008 23.3612 8.8968C23.7294 8.21941 23.7851 7.26347 22.7578 7.18433C21.8264 7.11153 21.5324 8.0105 21.9501 8.8145C22.4855 9.84958 22.2163 9.74195 21.9811 10.4763C20.3287 11.5304 16.7052 14.0627 16.2998 14.4615C16.207 16.4715 16.2689 18.6999 17.1043 20.4124C17.4416 21.1025 18.0079 21.9856 18.3545 22.4098C18.8867 23.0587 19.5891 23.2866 20.2049 23.9323C18.732 24.7743 14.0007 26.759 12.7784 25.2681C11.9862 24.3026 12.2462 16.5918 12.2431 14.8034C12.2369 12.3945 12.1564 12.1825 13.7036 11.0556L19.5396 6.69054C20.3287 6.11444 21.6376 4.81347 22.7423 4.84513C23.5871 4.87045 25.3292 6.31069 25.8986 6.76334L31.7129 11.1474C33.257 12.2806 33.0775 12.6224 33.0744 14.9711C33.0621 24.3153 34.1513 26.6007 28.8754 25.0687C27.8666 24.7743 26.6845 24.0336 25.3075 25.6479C25.9233 25.936 30.0048 27.1262 30.6856 27.1895C32.8145 27.392 34.3803 25.879 34.6031 23.9323C34.8228 22.0078 34.6309 18.7569 34.6309 16.6899C34.6309 10.5934 34.8692 11.4449 29.5314 7.51986C21.0713 1.29992 24.1193 1.48984 12.3112 10.1345C10.4576 11.4892 10.7175 12.7902 10.7175 15.6549C10.7175 18.0669 10.7206 20.482 10.7145 22.8941C10.7114 25.1605 11.5809 26.7368 13.5458 27.1325C15.0373 27.4332 23.2869 24.8882 24.6639 24.1507C27.8326 22.4541 29.6056 19.3393 29.0672 14.3666L29.0641 14.3761ZM17.881 15.0661L17.8841 16.5602L23.5561 19.9028C23.0208 21.4443 21.7119 20.175 20.9011 19.7129C20.0316 19.2191 18.8279 18.4499 18.0203 18.0257C18.1812 20.2858 20.4401 22.2008 22.1946 23.2739C23.29 23.945 26.8052 20.251 26.8733 18.9595C26.5082 18.7063 24.6113 17.6206 24.1564 17.3515L21.4272 15.7087C23.42 14.6958 23.5221 15.5884 25.7284 16.8925C25.9481 17.0191 27.0435 17.7029 27.1796 17.7282C27.3003 17.3198 27.2725 15.5631 27.2694 14.9363C27.1023 14.8129 22.7237 11.5874 22.6062 11.6159L18.1069 14.797C17.9522 14.892 17.881 14.8604 17.881 15.0693V15.0661Z"
                        fill="url(#paint0_linear_5402_37946)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_5402_37946"
                          x1="13.1961"
                          y1="7.88705"
                          x2="32.5185"
                          y2="22.1245"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0434DF" />
                          <stop offset="1" stop-color="#2A579F" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span>SAFE</span>
                  </div>
                  <div
                    class="check-mark"
                    :class="[
                      withdrawalType == 'safe' ? 'check-active' : 'check-mark',
                    ]"
                  >
                    <i class="fa fa-check"></i>
                  </div>
                </div>
                <div
                  class="option-withdraw"
                  @click="withdrawalType = 'bank'"
                  :class="[
                    withdrawalType == 'bank'
                      ? 'activeTypeWithdrawal'
                      : 'option-withdraw',
                  ]"
                >
                  <!-- <img src="" alt="bank"> -->
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.74023 11.1875H11.8652V12.3125H1.74023V11.1875Z"
                        fill="#5B68C0"
                      />
                      <path
                        d="M3.24023 5.5625H2.49023C2.28313 5.5625 2.11523 5.73039 2.11523 5.9375V10.4375C2.11523 10.6446 2.28313 10.8125 2.49023 10.8125H3.24023C3.44734 10.8125 3.61523 10.6446 3.61523 10.4375V5.9375C3.61523 5.73039 3.44734 5.5625 3.24023 5.5625Z"
                        fill="#4796E7"
                      />
                      <path
                        d="M5.86523 5.5625H5.11523C4.90813 5.5625 4.74023 5.73039 4.74023 5.9375V10.4375C4.74023 10.6446 4.90813 10.8125 5.11523 10.8125H5.86523C6.07234 10.8125 6.24023 10.6446 6.24023 10.4375V5.9375C6.24023 5.73039 6.07234 5.5625 5.86523 5.5625Z"
                        fill="#4796E7"
                      />
                      <path
                        d="M8.86523 5.5625H8.11523C7.90813 5.5625 7.74023 5.73039 7.74023 5.9375V10.4375C7.74023 10.6446 7.90813 10.8125 8.11523 10.8125H8.86523C9.07234 10.8125 9.24023 10.6446 9.24023 10.4375V5.9375C9.24023 5.73039 9.07234 5.5625 8.86523 5.5625Z"
                        fill="#4796E7"
                      />
                      <path
                        d="M11.4902 5.5625H10.7402C10.5331 5.5625 10.3652 5.73039 10.3652 5.9375V10.4375C10.3652 10.6446 10.5331 10.8125 10.7402 10.8125H11.4902C11.6973 10.8125 11.8652 10.6446 11.8652 10.4375V5.9375C11.8652 5.73039 11.6973 5.5625 11.4902 5.5625Z"
                        fill="#4796E7"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.48203 2.26568C6.65961 2.16073 6.94993 2.16215 7.12511 2.26568L11.7489 4.99844C11.9265 5.1034 11.905 5.18848 11.6955 5.18848H1.91162C1.70457 5.18848 1.68309 5.10197 1.85826 4.99844L6.48203 2.26568Z"
                        fill="#5B68C0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.9902 22.4375H23.1152V23.5625H12.9902V22.4375Z"
                        fill="#5B68C0"
                      />
                      <path
                        d="M14.4902 16.8125H13.7402C13.5331 16.8125 13.3652 16.9804 13.3652 17.1875V21.6875C13.3652 21.8946 13.5331 22.0625 13.7402 22.0625H14.4902C14.6973 22.0625 14.8652 21.8946 14.8652 21.6875V17.1875C14.8652 16.9804 14.6973 16.8125 14.4902 16.8125Z"
                        fill="#4796E7"
                      />
                      <path
                        d="M17.1152 16.8125H16.3652C16.1581 16.8125 15.9902 16.9804 15.9902 17.1875V21.6875C15.9902 21.8946 16.1581 22.0625 16.3652 22.0625H17.1152C17.3223 22.0625 17.4902 21.8946 17.4902 21.6875V17.1875C17.4902 16.9804 17.3223 16.8125 17.1152 16.8125Z"
                        fill="#4796E7"
                      />
                      <path
                        d="M20.1152 16.8125H19.3652C19.1581 16.8125 18.9902 16.9804 18.9902 17.1875V21.6875C18.9902 21.8946 19.1581 22.0625 19.3652 22.0625H20.1152C20.3223 22.0625 20.4902 21.8946 20.4902 21.6875V17.1875C20.4902 16.9804 20.3223 16.8125 20.1152 16.8125Z"
                        fill="#4796E7"
                      />
                      <path
                        d="M22.7402 16.8125H21.9902C21.7831 16.8125 21.6152 16.9804 21.6152 17.1875V21.6875C21.6152 21.8946 21.7831 22.0625 21.9902 22.0625H22.7402C22.9473 22.0625 23.1152 21.8946 23.1152 21.6875V17.1875C23.1152 16.9804 22.9473 16.8125 22.7402 16.8125Z"
                        fill="#4796E7"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.732 13.5157C17.9096 13.4107 18.1999 13.4122 18.3751 13.5157L22.9989 16.2484C23.1765 16.3534 23.155 16.4385 22.9455 16.4385H13.1616C12.9546 16.4385 12.9331 16.352 13.1083 16.2484L17.732 13.5157Z"
                        fill="#5B68C0"
                      />
                      <path
                        d="M4.36523 13.0625C4.36523 17.6188 8.05889 21.3125 12.6152 21.3125M20.8652 13.0625C20.8652 8.50615 17.1716 4.8125 12.6152 4.8125"
                        stroke="#80D25B"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    <span>Bank Account</span>
                  </div>
                  <div
                    class="check-mark"
                    :class="[
                      withdrawalType == 'bank' ? 'check-active' : 'check-mark',
                    ]"
                  >
                    <i class="fa fa-check"></i>
                  </div>
                </div>
              </div>

              <div class="withdrawal-btn">
                <button
                  @click="withdrawalType = ''"
                  type="button"
                  class="btn btn-block cancel"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-block continue"
                  @click="continueWithdrwalType"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal to choose withdrawal method ends -->

      <!-- Modal to confirm withdrawals starts -->
      <div class="modal fade" id="confirm-withdrawal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-top">
              <p>Enter Amount.</p>
              <span
                data-dismiss="modal"
                @click="
                  withdrawalType = '';
                  withdrawalAmount = '';
                  resetOtp()
                "
                >X</span
              >
            </div>
            <p class="option-text">
              Please enter the amount you would want to withdraw.
            </p>
            <hr />
            <div class="modal-body text-center">
              <div class="d-flex flex-column">
                <div class="balance-header">
                  <h6>Available Balance</h6>
                  <h5>{{ formattedBalance(readyBalance) }}</h5>
                </div>
                <div class="bank-details" v-if="withdrawalType == 'bank'">
                  <div>
                    <small>Bank Name</small>
                    <p>{{ bankName }}</p>
                  </div>
                  <div>
                    <small>Account number</small>
                    <p>{{ bankAccount }}</p>
                  </div>
                  <div>
                    <small>Account Name</small>
                    <p>{{ accountName }}</p>
                  </div>
                </div>
                <div class="form">
                  <label for="" class="d-flex">Amount</label>
                  <BaseInput
                    label=""
                    placeholder="Enter amount"
                    :value="theWithdrawalAmount"
                    @input="theWithdrawalAmount = $event"
                    :errorMessage="errorMessage.withdrawalAmount2"
                    @blur="validateWithdrawalAmount"
                  />
                </div>
                <p v-if="withdrawalType == 'bank'" class="percent-charge-text">1% Charges applies to bank withdrawal <span v-if="percentageAmount">{{ formattedBalance(percentageAmount) }}</span></p>
                <div class="balance-cal">
                  <div v-if="withdrawalAmount2 != ''">
                    <p>Withdrawing</p>
                    <small v-if="withdrawalType == 'bank'">{{ formattedBalance(thePercentage) }}</small>
                    <small v-if="withdrawalType == 'safe'">{{ formattedBalance(withdrawalAmount2) }}</small>
                  </div>
                  <div v-if="withdrawalAmount2 != '' && !disabledInput">
                    <p>New balance</p>
                    <small>{{ remainingBalance }}</small>
                  </div>
                </div>
              </div>

              <div class="withdrawal-btn">
                <button
                  @click="
                    withdrawalType = '';
                    withdrawalAmount = '';
                    resetOtp()
                  "
                  type="button"
                  class="btn btn-block cancel"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  v-if="withdrawalType == 'safe'"
                  type="button"
                  class="btn btn-block continue"
                  :disabled="withdrawing"
                  @click="withdrawToSafe"
                >
                  {{ withdrawing ? "Processing..." : "Submit" }}
                </button>
                <button
                  v-if="withdrawalType == 'bank'"
                  type="button"
                  class="btn btn-block continue"
                  @click="sendOTP" 
                >
                  {{ withdrawing2 ? "Processing..." : "Send OTP" }}
                </button>
                <!-- @click="sendOTP" -->
               
                 <!-- data-toggle="modal"
                  data-target="#otp"  -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal to confirm withdrawals ends -->

      <!-- Modal for OTP starts-->
      <div class="modal fade" id="otp">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-top">
              <p>Please check your email.</p>
              <span data-dismiss="modal" @click="withdrawalAmount = ''; resetOtp();">X</span>
            </div>
            <p class="option-text">We’ve sent an OTP code to {{ userEmail }}</p>
            <hr />
            <div class="modal-body text-center">
              <div class="d-flex flex-column">
                <div class="form otp-input">
                  <!-- @keyup="$refs.two.focus()" -->
                  <input
                    ref="one"
                    id="one"
                    maxlength="1"
                    v-model="one"
                    onfocus="(this.type='number')"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <input
                    ref="two"
                    id="two"
                    maxlength="1"
                    v-model="two"
                    onfocus="(this.type='number')"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <input
                    ref="three"
                    id="three"
                    maxlength="1"
                    v-model="three"
                    onfocus="(this.type='number')"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <input
                    ref="four"
                    id="four"
                    maxlength="1"
                    v-model="four"
                    onfocus="(this.type='number')"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <input
                    ref="five"
                    id="five"
                    maxlength="1"
                    v-model="five"
                    onfocus="(this.type='number')"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <input
                    ref="six"
                    id="six"
                    maxlength="1"
                    v-model="six"
                    onfocus="(this.type='number')"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                </div>

                <div class="countdown-resend">
                  <div>
                    <span>{{ displayMinutes }}</span
                    >:<span>{{ displaySeconds }}</span> <span>seconds</span>
                  </div>
                  <p>
                    Didn't get a code?
                    <button
                      :disabled="displayMinutes != 0 || displaySeconds != 0"
                      @click="reSendOTP()"
                      :class="[resendOTPLoading ? 'blinker_text' : '' ]"
                    >
                      {{ resendOTPLoading ? "Processing..." : "Click to resend" }}
                    </button>
                  </p>
                </div>
              </div>

              <div class="otp-btn">
                <button
                  v-if="withdrawalType == 'bank'"
                  type="button"
                  class="btn"
                  :disabled="withdrawing || resendOTPLoading"
                  @click="OTPValidate()"
                >
                  {{ withdrawing ? "Processing..." : "Submit" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal for OTP ends -->

      <InviteFriends />
    </section>
  </div>
</template>

<script>
import InviteFriends from "./InviteFriends.vue";
import Loader from "./Loader.vue";
import realtor from "../services/realtor";
import CF from "../helpers/currencyFormatter";
import transactions from "../services/transactions";
import formatDate from "../helpers/extractDate";
import TheModal from "./UI/TheModal.vue";
import TheButton from "./UI/TheButton.vue";
import BaseInput from "../components/form/BaseInput.vue";
import $ from "jquery";

export default {
  name: "Wallet",
  components: {
    InviteFriends,
    Loader,
    TheModal,
    TheButton,
    BaseInput,
  },
  data() {
    return {
      percentageAmount: "",
      readyBalance: 0,
      expectedBalance: 0,
      bonusBalance: 0,
      allTransactions: [],
      readyThisMonth: 0,
      expectedThisMonth: 0,
      fetchData: "",
      isModalOpen: false,
      requestingBonusPayment: false,
      withdrawing: false,
      withdrawing2: false,
      resendOTPLoading: false,
      subscription: "",
      withdrawalType: "",
      withdrawalAmount: "",
      withdrawalAmount2: "",
      remainingBalance: "",
      disabledInput: false,
      thePercentage: "",
      errorMessage: {
        withdrawalAmount2: "",
        form: "",
      },
      userEmail: "",
      bankName: "",
      bankAccount: "",
      accountName: "",
      passportUrl: "",
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      six: "",
      displayMinutes: "",
      displaySeconds: "",
      displayHours: "",
      nextFiveMinutes: "",
      timer: "",
      focusNext(elem) {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem);
      elem.form.elements.item(
        currentIndex < elem.form.elements.length - 1 ?
        currentIndex + 1 :
        0
      ).focus();
    }
    };
  },
  computed: {
    currentMonth() {
      const date = new Date();
      const monthList = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let month = date.getMonth();

      return monthList[month];
    },
    isWithdrawalFormValid() {
      return this.validateWithdrawalAmount();
    },
    theWithdrawalAmount: {
      get() {
        return this.withdrawalAmount;
      },
      set(value) {
        let amount = value.split(",").join("");
        if (this.withdrawalType === "bank") {
            this.percentageAmount = (amount / 100 );
            let value2 = Number(this.percentageAmount) + Number(amount);
            this.thePercentage = value2;
        }
        this.withdrawalAmount2 = (amount);
        this.estimateRemainingBalance(amount);
        let decimalArray = amount.split(".");
        if (decimalArray.length === 2) {
          amount = decimalArray[0];
          amount = (+amount).toLocaleString();
          this.withdrawalAmount = amount + "." + decimalArray[1];
        } else {
          this.withdrawalAmount = (+amount).toLocaleString();
        }
      },
    },
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
    end() {
      return new Date(
        this.year,
        this.month,
        this.date,
        this.hour,
        this.minutes,
        this.seconds,
        this.milliseconds
        // 2020, 2, 15, 10, 10, 10,10
      );
    },
  },
  methods: {
    addMinutes(date, minutes) {
      date.setMinutes(date.getMinutes() + minutes);
      return date;
    },
    
    formartNum: (num) => (num < 10 ? "0" + num : num),
    showRemaining() {
      this.nextFiveMinutes = this.addMinutes(new Date(), 5);
      this.timer = setInterval(() => {
        const now = new Date();
        const end = this.nextFiveMinutes;
        const distance = end.getTime() - now.getTime();
        if (distance < 0) {
          clearInterval(this.timer);
          return;
        }
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
        this.displaySeconds = this.formartNum(seconds);
        // console.log("MINSEC", this.displayMinutes, this.displaySeconds);
      }, 1000);
    },

    formattedBalance(amount) {
      return CF.naira.format(amount);
    },
    toTwoDecimal(value) {
      return CF.toTwoDecimal(value);
    },
    formatDate(date) {
      return formatDate(date);
    },
    clearForm() {
      this.withdrawalAmount = "";
    },
    estimateRemainingBalance(amount) {
    let newBalance = (Number(amount) + Number(this.percentageAmount));
      if (this.withdrawalType == "bank" && (newBalance > this.readyBalance)) {
        return (this.disabledInput = true);
      }
      if (this.withdrawalType == "safe" && (amount > this.readyBalance)) {
        return (this.disabledInput = true);
      } else {
        let newBalance = this.readyBalance - amount;
        if (this.withdrawalType == "bank") {
          this.remainingBalance = this.formattedBalance(newBalance - Number(this.percentageAmount));
        } else {
          this.remainingBalance = this.formattedBalance(newBalance);
        }
        return (this.disabledInput = false);
      }
    },
    async withdrawToSafe() {
      // this.withdrawing = true;
      if (this.subscription != "YES") {
        this.withdrawing = false;
        return this.$toast.error("Subscribe to withdraw");
      }
      if (this.disabledInput == true && this.withdrawalAmount2 != "") {
        this.withdrawing = false;
        return this.$toast.error("Insufficient balance");
      }
      if (this.isWithdrawalFormValid) {
        const body = {
          // id: this.$store.getters.getUser.id,
          amount: this.withdrawalAmount2,
        };
        this.withdrawing = true;
        const data = await transactions.safeWithdrawal(JSON.stringify(body));

        try {
          console.log(data);
          this.withdrawing = false;
          if (data.success) {
            this.$toast.success("Waithdrawal Successfull");
            $("#confirm-withdrawal")
              .removeClass("fade")
              .modal("hide");
            this.clearForm();
            this.bonusDetails();
            // this.isRegisteringTenant = true;
          }
          if (data.error) {
            return this.$toast.error(data.error);
          }
        } catch (err) {
          this.withdrawing = false;
          this.$toast.error("Unable to submit request. Try again");
          $("#confirm-withdrawal")
            .removeClass("fade")
            .modal("hide");
        }
      }
    },
    async withdrawToBank() {
      this.withdrawing = true;
      if (this.subscription != "YES") {
        this.withdrawing = false;
        return this.$toast.error("Subscribe to withdraw");
      }
      if (this.disabledInput == true && this.withdrawalAmount2 != "") {
        this.withdrawing = false;
        return this.$toast.error("Insufficient balance");
      }
      if (this.bankName === "" || this.bankAccount === "" || this.accountName === "") {
        this.withdrawing = false;
        return this.$toast.error("Bank details not found, update your account");
      }
      if (this.isWithdrawalFormValid) {
        let userId = this.$store.getters.getUser.id.toString();
        const body = {
          userid: userId,
          amount: Number(this.thePercentage),
        };
        this.withdrawing = true;
        const data = await transactions.applyForWithdrawal(
          JSON.stringify(body)
        );

        try {
          console.log(data);
          this.withdrawing = false;
          if (data.success) {
            this.$toast.success("Waithdrawal Successfull");
            $("#otp")
              .removeClass("fade")
              .modal("hide");
            this.clearForm();
            this.bonusDetails();
          }
          if (data.error) {
            return this.$toast.error(data.error);
          }
        } catch (err) {
          this.withdrawing = false;
          this.$toast.error("Unable to submit request. Try again");
          $("#otp")
            .removeClass("fade")
            .modal("hide");
        }
      }
    },
    validateWithdrawalAmount() {
      if (this.withdrawalAmount2.trim() === "") {
        this.errorMessage.withdrawalAmount2 = " Amount required!";
        return false;
      }
      if (isNaN(this.withdrawalAmount2.trim())) {
        this.errorMessage.withdrawalAmount2 = " Amount must be a number";
        return false;
      }

      this.errorMessage.withdrawalAmount2 = "";
      return true;
    },
    async sendOTP() {
      if (this.subscription != "YES") {
        this.withdrawing2 = false;
        return this.$toast.error("Subscribe to withdraw");
      }
    // this.bankAccount = user.accountnumber;
    // this.accountName = user.accountname;
      if (this.bankName === null || this.bankAccount === null || this.accountName === null) {
        this.withdrawing2 = false;
        return this.$toast.error("Update your bank details from account settings");
      }
      if (this.disabledInput == true && this.withdrawalAmount2 != "") {
        this.withdrawing2 = false;
        return this.$toast.error("Insufficient balance");
      }
      if (this.withdrawalAmount2  === "") {
        this.withdrawing2 = false;
        return this.$toast.error("Enter Amount");
      }
      if (this.withdrawalAmount2 < 5000) {
        this.withdrawing2 = false;
        return this.$toast.error("Amount must not be less than 5,000");
      }
      if (this.bankName === "" || this.bankAccount === "" || this.accountName === "") {
        this.withdrawing = false;
        return this.$toast.error("Bank details not found, update your account");
      }
      if (this.bankName === null || this.bankAccount === null || this.accountName === null) {
        this.withdrawing = false;
        return this.$toast.error("Bank details not found, update your account");
      }
      if (this.isWithdrawalFormValid) {
        const body = {
          // id: this.$store.getters.getUser.id,
          amount: this.withdrawalAmount2,
        };
        this.withdrawing2 = true;
        const data = await transactions.sendOtp(JSON.stringify(body));

        try {
          this.withdrawing2 = false;
          this.resendOTPLoading = false;
          if (data.success) {
            this.$toast.success("OTP sent to your email");
            $("#confirm-withdrawal")
              .removeClass("fade")
              .modal("hide");
            $("#otp")
              .addClass("fade")
              .modal("show");
            this.showRemaining();
            this.clearForm();
          }
          if (data.error) {
            return this.$toast.error(data.error);
          }
        } catch (err) {
          this.withdrawing2 = false;
          this.$toast.error("Unable to submit request. Try again");
          $("#confirm-withdrawal")
            .removeClass("fade")
            .modal("hide");
        }
      }
    },
    async reSendOTP() {
      this.resendOTPLoading = true;
      if (this.subscription != "YES") {
        this.resendOTPLoading = false;
        return this.$toast.error("Subscribe to withdraw");
      }
      if (this.disabledInput == true && this.withdrawalAmount2 != "") {
        this.resendOTPLoading = false;
        return this.$toast.error("Insufficient balance");
      }
      if (this.withdrawalAmount2 < 5000) {
        this.resendOTPLoading = false;
        return this.$toast.error("Amount must not be less than 5,000");
      }
      if (this.isWithdrawalFormValid) {
        const body = {
          // id: this.$store.getters.getUser.id,
          amount: this.withdrawalAmount2,
        };
        const data = await transactions.sendOtp(JSON.stringify(body));

        try {
          this.resendOTPLoading = false;
          if (data.success) {
            this.$toast.success("OTP resent to your email");
            this.showRemaining();
          }
          if (data.error) {
            return this.$toast.error(data.error);
          }
        } catch (err) {
          this.resendOTPLoading = false;
          this.$toast.error("Unable to submit request. Try again");
          $("#confirm-withdrawal")
            .removeClass("fade")
            .modal("hide");
        }
      }
    },
    resetOtp() {
        this.one = "";
        this.two = "";
        this.three = "";
        this.four = "";
        this.five = "";
        this.six = "";
        this.withdrawalType = "";
        this.remainingBalance = "";
        this.thePercentage = "";
        this.withdrawalAmount2 = "";
        this.percentageAmount = "";
        this.resetAllData();
    },
    // isNumber: function(evt) {
    //   console.log("evt" , evt);
    //   const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    //   const keyPressed = evt.key;
    //   console.log("keys" , keysAllowed);
    //   if (!keysAllowed.includes(keyPressed)) {
    //     console.log("isinclude" , keyPressed);
    //     return evt.preventDefault();
    //     // evt.stopPropagation();
    //     // return false;
    //   }
    // },
    resetAllData() {
      this.readyBalance;
      this.withdrawing2;
      this.thePercentage;
      this.withdrawalAmount2;
      this.thePercentage = "";
      this.withdrawalAmount2 = "";
      this.percentageAmount = "";
      clearInterval(this.timer);
      // this.showRemaining();
    },
    async OTPValidate() {
      this.withdrawing = true;
      if (
        this.one === "" ||
        this.two == "" ||
        this.three == "" ||
        this.four == "" ||
        this.five == "" ||
        this.six == ""
      ) {
        this.withdrawing = false;
        return this.$toast.error("Enter complete OTP");
      }
      const otp = `${this.one}${this.two}${this.three}${this.four}${this.five}${this.six}`;
      if (this.isWithdrawalFormValid) {
        const body = {
          // id: this.$store.getters.getUser.id,
          otp: otp,
        };
        this.withdrawing = true;
        const data = await transactions.validateOtp(JSON.stringify(body));

        try {
          this.withdrawing = false;
          if (data.success) {
            this.$toast.success("Valid OTP");
            this.withdrawToBank();
          }
          if (data.error) {
            return this.$toast.error(data.error);
          }
        } catch (err) {
          this.withdrawing = false;
          this.$toast.error("Unable to submit request. Try again");
        }
      }
    },
    bonusDetails() {
      let userID = this.$store.getters.getUser.id;
      this.fetchData = "loading";
      this.subscription = this.$store.getters.getSubscription;
      transactions
        .walletDetails(userID)
        .then((data) => {
          // console.log("data", data);
          if (data.success) {
            this.allTransactions = data.transactions;
            this.readyBalance = data.readywallet;
            this.expectedBalance = data.expected;
            this.readyThisMonth = data.readyThisMonth;
            this.expectedThisMonth = data.expectedThisMonth;
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        })
        .finally(() => {
          this.fetchData = "loaded";
        });
    },
    withdraw() {
      // this.readyBalance = 6000;
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe to withdraw");
      }
      if (this.readyBalance < 5000) {
        return this.$toast.error("Balance must be up to 5000");
      } else {
        this.$router.push("withdraw");
      }
    },
    getUserActivationDataAmount() {
      realtor
        .fetchActivationData()
        .then((data) => {
          if (data.activateddata) {
            if (data.activateddata.status == 2) {
              this.bonusBalance = 0;
              return;
            }
            this.bonusBalance = data.activateddata.amount;
          } else {
            // this.$toast.warning("Unable to verify bonus balance ");
            this.bonusBalance = 0;
          }
        })
        .catch((err) => {
          console.log(err);

          this.$toast.error("Please check your network and refresh page");
        });
    },
    continueWithdrwalType() {
      if (this.withdrawalType == "") {
        return this.$toast.error("Select withdrawal method");
      }
      if (this.withdrawalType == "safe" || this.withdrawalType == "bank") {
        $("#withdraw-options")
          .removeClass("fade")
          .modal("hide");
        $("#confirm-withdrawal")
          .addClass("fade")
          .modal("show");
      }
    },
    openModal() {
      if (this.bonusBalance > 0 && this.subscription === "YES") {
        return (this.isModalOpen = true);
      }
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe to withdraw");
      } else {
        this.$toast.error("Sorry, You have no bonus to withdraw");
      }
    },
    closeModal() {
      this.isModalOpen = false;
    },
    withdrawBonus() {
      this.requestingBonusPayment = true;
      realtor
        .withdrawActivationBonus()
        .then((data) => {
          if (data.success === "done") {
            this.closeModal();
            this.$store.dispatch(
              "saveActivatedStatus",
              data.activateddata.status
            );

            this.$toast.success("Request received successfully");
            this.requestingBonusPayment = false;
          }
        })
        .catch((err) => {
          this.$toast.error("Sorry, There was an error in your request");
          console.log(err);
        });
    },
  },
  mounted() {
    this.bonusDetails();
    // this.showRemaining();
  },
  created() {
    // console.log(this.$store.state.user.phone);
    const user = this.$store.getters.getUser;
    this.userID = user.id;
    this.bankName = user.bankname;
    this.bankAccount = user.accountnumber;
    this.accountName = user.accountname;
    this.passportUrl = user.passportfile;
    this.userEmail = user.email;
    this.getUserActivationDataAmount();
  },
};
</script>

<style scoped lang="scss">
@import "../styles/table.css";
@import "../styles/section-header.css";

/* Modal withdraw option */
.modal-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0;
  p {
    font-size: 18px;
    font-weight: 600;
    color: #2b3352;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
  }
}
.option-text {
  padding: 0 24px;
  color: #555c74;
  // font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.option-withdraw {
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  height: 64px;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  span {
    // font-family: Inter;
    color: #555c74;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-left: 12px;
  }
}
.activeTypeWithdrawal {
  border: 1px solid #0033ea;
  background: #dde4fc;
  cursor: pointer;
}

.check-mark {
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 50px;
  i {
    font-size: 10px;
    display: flex;
    align-items: center;
    padding: 3px;
    color: #ffffff;
  }
}
.check-active {
  background: #0033ea;
  border: none;
}
.withdrawal-btn {
  display: flex;
  gap: 12px;
  margin-top: 32px;
  .cancel {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #ffffff;
    color: #2b3352;
    font-size: 16px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    outline: 0;
    box-shadow: none;
  }
  .continue {
    border-radius: 8px;
    border: 1px solid #0033ea;
    background: #0033ea;
    color: #ffffff;
    font-size: 16px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    margin-top: 0;
    outline: 0;
    box-shadow: none;
  }
}

.balance-header {
  h6 {
    color: #2b3352;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  h5 {
    color: #2b3352;
    text-align: center;
    // font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}
label {
  p:first-child() {
    text-align: left !important;
  }
}

.percent-charge-text {
  font-size: 11px;
  color: #57595a;
  font-family: Lato;
  text-align: left;
  margin-top: 10px !important;
}
.balance-cal {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  div:first-child {
    p,
    small {
      font-style: normal;
      line-height: 150%;
      font-size: 14px;
      font-family: Lato;
      text-align: left;
      display: flex;
    }
    p {
      color: #555c74;
      font-weight: 400;
    }
    small {
      color: #eb0000;
      font-weight: 600;
    }
  }
  div:nth-child(2) {
    p,
    small {
      font-style: normal;
      line-height: 150%;
      font-size: 14px;
      font-family: Lato;
      text-align: right;
      display: flex;
    }
    p {
      color: #555c74;
      font-weight: 400;
    }
    small {
      color: #2bc300;
      font-weight: 600;
    }
  }
}
.bank-details {
  background: #f5f5f7;
  padding: 16px;
  margin-bottom: 20px;
  div {
    margin-bottom: 12px;
    small {
      color: #555c74;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: flex;
    }
    p {
      color: #2b3352;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      display: flex;
    }
  }
}
.otp-input {
  input {
    border-radius: 8px;
    border: 2px solid #0033ea;
    background: var(--Base-White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8px;
    width: 45px;
    height: 60px;
    margin: 0 6px;
    text-align: center;
    box-shadow: none;
    outline: none;

    color: #0033ea;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  input:first-child {
    margin-left: 0;
  }
  input:last-child {
    margin-right: 0;
  }
}
.countdown-resend {
  margin-top: 20px;
  div {
    span {
      color: #555c74;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  p {
    color: #555c74;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    button {
      text-decoration-line: underline;
      color: #2b3352;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      background: none;
    }
  }
}
.blinker_text {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.otp-btn {
  margin: auto;
  margin-top: 30px;
  button {
    color: #555c74;
    border: 0.5px solid #555c74;
    font-size: 14px;
    outline: 0;
    box-shadow: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Modal withdraw option ends */

.wallets {
  display: flex;
  gap: 2rem;
}
.wallet {
  margin-top: 20px;
  background: #fff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 536px;
  width: 50%;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.wallet-action {
  display: flex;
  justify-content: space-between;
  padding: 20px 35px 0 22px;
  margin-bottom: auto;
}
.hint-container {
  position: relative;
}
.hint {
  position: absolute;
  padding: 8px;
  background: #c4c4c4;
  width: 200px;
  display: none;
}
.hint > span {
  display: inline-block;
  border-color: #c4c4c4 transparent transparent #c4c4c4;
  border-style: solid;
  border-width: 5px;
  position: absolute;
  left: -5px;
  transform: rotateZ(-45deg);
}
.hint > p {
  font-size: 12px;
  font-family: "Gotham-Book";
}
.hint-container > img {
  cursor: pointer;
  margin-right: 10px;
}
.hint-container > img:hover + .hint {
  display: inline-block;
}
.withdraw-btn {
  padding: 5px 10px;
  background: var(--primary-color);
  border-radius: 20px;
  border: 0;
  color: #fff;
  font-family: "Gotham-Book";
}
.wallet .price {
  font-size: 36px;
  line-height: 1;
  font-family: "Gotham-Medium";
  text-align: center;
  margin-bottom: 10px !important;
}
.wallet-description {
  font-size: 18px;
  line-height: 1;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
  font-family: "Gotham-Book";
}
.wallet .expected {
  margin: 34px 0 0 auto;
  background: var(--green-color);
  width: fit-content;
  color: #fff;
  line-height: 46px;
  font-size: 14px;
  padding: 0 27px 0 17px;
}
.expected-amount {
  font-weight: 600;
}

.section-two {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* table styling */
.table-container {
  width: 699px;
  margin-right: 20px;
  height: fit-content;
}
/* table container styling ended */

.confirm-withdrawal {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  max-width: 400px;
}

.confirm-withdrawal span {
  color: var(--primary-color);
}

@media (max-width: 991.98px) {
  .wallet:first-of-type {
    margin-right: 30px;
  }
  .wallet .price {
    font-size: 30px;
  }
  .wallet-action {
    padding: 20px 20px 0;
  }
  .table-container {
    margin-right: 0px;
  }
}
@media (max-width: 575.98px) {
  .wallets {
    flex-wrap: wrap;
    justify-content: center;
  }
  .wallet {
    width: 100%;
    max-width: 450px;
  }
  .wallet:first-of-type {
    margin-right: 0;
  }
  .wallet-description {
    font-size: 16px;
  }
  .otp-input {
    input {
      width: 36px;
    }
  }
}
@media (max-width: 424.98px) {
  .wallet {
    height: 220px;
  }
  .wallet .price {
    font-size: 26px;
  }
  .wallet-action {
    padding: 20px 20px 0;
  }
}
</style>
