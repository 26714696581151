<template>
    <div class="invite-friends-container">
        <h3 class="invite-friends">Invite friends</h3>
        <img src="@/assets/images/invite-friends.svg" alt="">
        <h4>Earn more</h4>
        <p>Recruit your friends and earn rewards and commission</p>
        <router-link to="/user/recruit-realtor">
            <button>Invite Friends</button>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "InviteFriends",
}
</script>

<style scoped>
@import "../styles/table.css";

    /* invite friends styling */
    .invite-friends-container {
        margin-top: 32px;
        background: #fff;
        max-width: 370px;
        width: 30%;
        min-width: 280px;
        padding-bottom: 30px;
    }
    .invite-friends-container a {
        margin: 0 auto;
        display: block;
        width: fit-content;
        text-decoration: none;
    }
    .invite-friends {
        padding: 18px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 14px;
        font-family: "Gotham-Book";
    }
    .invite-friends-container > img {
        display: block;
        margin: 28px auto 17px;
    }
    .invite-friends-container > h4 {
        text-align: center;
        font-size: 14px;
        font-family: "Gotham-Book";
    }
    .invite-friends-container > p {
        font-size: 14px;
        margin: 15px auto;
        /* width: 70%;
        min-width: 240px; */
        padding: 10px 40px;
        text-align: center;
        font-family: "Gotham-Book";
    }
    .invite-friends-container button {
        background: var(--primary-color);
        color: #fff;
        height: 40px;
        width: 179px;
        display: block;
        margin: 0 auto;
        border: 0;
        font-family: "Gotham-Book";
    }
    @media (max-width: 991.98px) {
        .invite-friends-container {
            width: 100%;
        }
    }
</style>