const currencyFormatter = {
    naira: new Intl.NumberFormat('en-NG',
        {style: 'currency', currency: 'NGN'}
    ),
    toTwoDecimal: (value) => {
        // to ensure that everything must have two decimal point
        value = (+value).toFixed(2);
        value = (+value).toLocaleString(undefined, {minimumFractionDigits: 2});
        return value;
    },
    toFourDecimal: (value) => {
        // to ensure that everything must have two decimal point
        value = (+value).toFixed(4);
        value = (+value).toLocaleString(undefined, {minimumFractionDigits: 4});
        return value;
    },
}

export default currencyFormatter;
